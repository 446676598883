<template>
  <v-row
    no-gutters
    class="user-column"
    align="baseline"
  >
    <v-col
      cols="auto"
      class="avatar-col"
    >
      <v-avatar size="25">
        <img
          v-if="user.avatar"
          :src="user.avatar"
          :alt="UserName"
          @error="$event.target.src = img404"
        >
        <v-icon
          v-else
          color="primary"
        >
          mdi-account-circle
        </v-icon>
      </v-avatar>
    </v-col>
    <v-col class="name-col">
      <div class="body-s-semibold">
        <!-- <span>{{ UserName }}</span>  <span>({{ value.account_name ||'-' }})</span> -->
        <span>{{ clientNameDisplay }}</span>
        <span v-if="genderIcon">&nbsp;<v-icon
          :color="genderIcon.color"
          x-small
        >{{ genderIcon.icon }}</v-icon> </span>
      </div>
      <div
        v-if="showLastActivity"
        class="body-xs-semibold neutral-600--text"
      >
        Был(а) в сети
        {{ user && user.last_activity ? $moment.utc(user.last_activity).local().format("DD.MM.YYYY\u00A0HH:mm") : '-' }}
      </div>
    </v-col>
  </v-row>
</template>

  <script>
  export default {
    name: 'AccountColumn',

    props: {
      value: {
        type: [Object],
        required: true,
        default: () => {},
      },
      showLastActivity: {
        type: Boolean,
        default: true,
      },
      showGender: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      user () {
        return this.value && this.value.user
      },
      clientNameDisplay () {
        return this.value.client_name || this.$t('no_name')
      },
      genderIcon () {
        if (!this.showGender || !this.value.gender) return null

        return {
          m: { icon: 'mdi-gender-male', color: 'primary' },
          f: { icon: 'mdi-gender-female', color: 'pink' },
        }[this.value.gender]
      },
      UserName () {
        if (this.user) {
          if (this.user.UserName) {
            return this.user.UserName
          } else {
            if (this.user.name || this.user.lastname || this.user.middlename) {
              return [this.user.lastname, this.user.name, this.user.middlename].join(' ').trim()
            } else {
              if (this.user.phone) return this.user.phone
              else if (this.user.email) return this.user.email
              else return 'Пользователь'
            }
          }
        } else {
          return ''
        }
      },
      img404 () {
        const fileStorage = this.$config.app.fileStorage
        return fileStorage + 'users/avatars/default.png'
      },
    },
    methods: {},
  }
</script>
<style lang="scss" scoped>
.user-column{
    margin: 0px -4px;
}
.avatar-col, .name-col {
    margin: 0px 4px;
}

.avatar {
  width: 25px;
  height: 25px;
  border-radius: 40px;
  margin-right: 8px;
}
</style>
